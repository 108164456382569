<template>
    <o-data-lookup :data-object="dsDocuments">
        <template #toolbarActions>
            <slot name="toolbarActions"></slot>
        </template>
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">
                <slot name="document"></slot>
            </span>
        </template>
        <o-column field="ID" width="100"></o-column>
        <o-column field="OrgUnit" width="100"></o-column>
        <o-column field="Name" width="100"></o-column>
        <o-column field="Title" width="350"></o-column>
        <o-column field="Rev" width="100"></o-column>
        <o-column field="DocumentTypeAndTitle" width="200"></o-column>
        <o-column field="OrgUnitWithParent" width="200"></o-column>
        <o-column field="Status" width="200"></o-column>

    </o-data-lookup>
</template>

<script setup>
import { computed, watch } from 'vue';

const props = defineProps({
    textInput: Boolean,
    is: String,
    textInputValue: String,
    whereClause: String,
});

const whereClause = computed(() => props.whereClause);

const dsDocumentsDef = {
    id: 'dsDocuments' + crypto.randomUUID(),
    viewName: 'aviw_Arena_DocsLookupNewAccess',
    maxRecords: 25,
    whereClause: whereClause.value ? whereClause.value : '',
    loadRecents: false,
    distinctRows: true,
    fields: [
        { name: "ID", type: "string" },
        { name: "Name", type: "string" },
        { name: "Title", type: "string" },
        { name: "OrgUnit", type: "string" },
        { name: "Status", type: "string" },
        { name: "Status_ID", type: "int"},
        { name: "Rev", type: "string" },
        { name: "DocumentTypeAndTitle", type: "string" },
        { name: "OrgUnitWithParent", type: "string" },
        { name: "Extension", type: "string" }
    ]
};

const dsDocuments = $getOrCreateDataObject(dsDocumentsDef);

const getWhereClause = () => {
    let vWhereClause = whereClause.value ? whereClause.value : '';
    vWhereClause += (restrictToContext.value) ? ` AND OrgUnitWithParent LIKE '${context.idPath}%'` : ` AND 1 = 1`;
    vWhereClause += (!includeObsolete.value) ? ` AND Status != 'Obsolete'` : ``;
    return vWhereClause;
}

const reloadMainDS = () => {
    dsDocuments.recordSource.whereClause = getWhereClause();
    dsDocuments.load();
}

watch(whereClause, () => {
    dsDocuments.recordSource.whereClause = whereClause.value;
    dsDocuments.load();
});

const statusColors = {
    "danger": [ "Not Approved", "Required Fields Missing", "Check Not Approved" ],
    "dark": [ "Draft" ],
    "info": [ "Received" ],
    "light": [ "Planned" ],
    "primary": [ "New Revision", "Created", "Draft Revision" ],
    "secondary": [ "Obsolete", "Deleted" ],
    "success": [ "Checked", "Released", "Review Completed", "Approved", "IDC Completed", "3rd Party Review Completed" ],
    "warning": [ "Review Ongoing", "To Be Checked", "To Be Approved", "To Be Revised", "To Be Verified", "To Be Delivered", "IDC in Progress", "3rd Party Review Ongoing", "Released | New Revision Being Prepared" ],
}

const getStatusColor = (status) => {
    for (const color in statusColors) {
        if (statusColors[color].includes(status)) {
            console.log(color);
            return color;
        }
    }
    return "primary";
};
</script>
